var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "20px" }
              },
              [
                _vm._v(
                  _vm._s(
                    this.formData.sign == "add"
                      ? _vm.$t("ADD Contract")
                      : _vm.$t("EDIT Contract")
                  )
                )
              ]
            )
          ]),
          [
            _c(
              "div",
              { staticClass: "Info" },
              [
                _c(
                  "el-form",
                  {
                    ref: "from",
                    attrs: {
                      model: _vm.formData,
                      "label-position": "right",
                      "show-message": _vm.notip
                    }
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("ContractNo"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("ContractNo"),
                                      prop: "contractNo",
                                      "label-width": "120px",
                                      required: true,
                                      rules:
                                        _vm.formData.fromaDataRules.contractNo
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        maxlength: "64",
                                        disabled: _vm.diasabledInput,
                                        clearable: "true"
                                      },
                                      model: {
                                        value: _vm.formData.contractNo,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "contractNo",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "formData.contractNo"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("CooperativeCompany"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("CooperativeCompany"),
                                      prop: "partnerCorpId",
                                      "label-width": "120px",
                                      required: true,
                                      rules:
                                        _vm.formData.fromaDataRules
                                          .partnerCorpId
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: {
                                          width: "calc(100% - 115px)"
                                        },
                                        attrs: {
                                          placeholder: "",
                                          filterable: "",
                                          clearable: "true"
                                        },
                                        model: {
                                          value: _vm.formData.partnerCorpId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.formData,
                                              "partnerCorpId",
                                              $$v
                                            )
                                          },
                                          expression: "formData.partnerCorpId"
                                        }
                                      },
                                      _vm._l(
                                        this.formData.CooperativeData,
                                        function(item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value
                                            }
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("SigningTime"),
                                  prop: "signDate",
                                  "label-width": "120px",
                                  required: true,
                                  rules: _vm.formData.fromaDataRules.signDate
                                }
                              },
                              [
                                _c("el-date-picker", {
                                  staticClass: "dataicon",
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: {
                                    "value-format": "yyyy-MM-dd",
                                    type: "date",
                                    "prefix-icon": ""
                                  },
                                  model: {
                                    value: _vm.formData.signDate,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formData, "signDate", $$v)
                                    },
                                    expression: "formData.signDate"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("EffectiveDate"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("EffectiveDate"),
                                      prop: "effectiveDate",
                                      "label-width": "120px"
                                    }
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        type: "daterange",
                                        align: "right",
                                        "unlink-panels": "",
                                        "range-separator": "-",
                                        "start-placeholder": "",
                                        "end-placeholder": "",
                                        "value-format": "yyyy-MM-dd"
                                      },
                                      model: {
                                        value: _vm.formData.effectiveDate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "effectiveDate",
                                            $$v
                                          )
                                        },
                                        expression: "formData.effectiveDate"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("Brand"),
                                  prop: "brand",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: {
                                      width: "calc(100% - 115px)"
                                    },
                                    attrs: {
                                      filterable: "",
                                      placeholder: "",
                                      clearable: "true"
                                    },
                                    on: {
                                      change: _vm.selectbrand,
                                      focus: _vm.loadbrand
                                    },
                                    model: {
                                      value: _vm.formData.brand,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formData, "brand", $$v)
                                      },
                                      expression: "formData.brand"
                                    }
                                  },
                                  _vm._l(this.formData.dictData, function(
                                    item
                                  ) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("ContractCategory"),
                                  placement: "top-start"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: _vm.$t("ContractCategory"),
                                      prop: "contractTypeName",
                                      "label-width": "120px"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "calc(100% - 115px)"
                                      },
                                      attrs: {
                                        maxlength: "64",
                                        clearable: "true"
                                      },
                                      model: {
                                        value: _vm.formData.contractTypeName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "contractTypeName",
                                            $$v
                                          )
                                        },
                                        expression: "formData.contractTypeName"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12, sm: 24, md: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: _vm.$t("REMARK"),
                                  prop: "remark",
                                  "label-width": "120px"
                                }
                              },
                              [
                                _c("el-input", {
                                  staticStyle: { width: "calc(100% - 115px)" },
                                  attrs: {
                                    maxlength: "300",
                                    clearable: "true"
                                  },
                                  model: {
                                    value: _vm.formData.remark,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formData, "remark", $$v)
                                    },
                                    expression: "formData.remark"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "smallTit" }, [
                      _vm._v(_vm._s(_vm.$t("DetailedInfo")))
                    ]),
                    _c(
                      "el-table",
                      {
                        attrs: {
                          data: _vm.formData.tableData,
                          "row-class-name": _vm.tableRowClassName,
                          "header-cell-class-name": _vm.starAdd
                        },
                        on: { "current-change": _vm.handleCurrentChange }
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("Index"),
                            type: "index",
                            width: "50px"
                          }
                        }),
                        _vm.formData.show
                          ? _c("el-table-column", [
                              _c("span", [_vm._v(_vm._s(_vm.scope.row.Id))])
                            ])
                          : _vm._e(),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("ProductCode"),
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.Code))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." + scope.$index + ".Code",
                                        rules: _vm.formData.fromaDataRules.Code
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-autocomplete", {
                                            attrs: {
                                              "fetch-suggestions": _vm.pcSearch,
                                              clearable: "true"
                                            },
                                            model: {
                                              value: scope.row.Code,
                                              callback: function($$v) {
                                                _vm.$set(scope.row, "Code", $$v)
                                              },
                                              expression: "scope.row.Code"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("Product Name"),
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.ProductName))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".ProductName"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-autocomplete", {
                                            attrs: {
                                              "fetch-suggestions": _vm.pnSearch,
                                              clearable: "true"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.MGchange(scope.row)
                                              }
                                            },
                                            model: {
                                              value: scope.row.ProductName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "ProductName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.ProductName"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("COLOR"), align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.Color))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." + scope.$index + ".Color"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-input", {
                                            attrs: {
                                              maxlength: "64",
                                              clearable: "true"
                                            },
                                            model: {
                                              value: scope.row.Color,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "Color",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.Color"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("NUMBER"), align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.Number))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".Number",
                                        rules:
                                          _vm.formData.fromaDataRules.Number
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-input-number", {
                                            staticStyle: {
                                              width: "calc(100% - 20px)"
                                            },
                                            attrs: {
                                              controls: false,
                                              clearable: "",
                                              min: 0
                                            },
                                            model: {
                                              value: scope.row.Number,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "Number",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.Number"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("UNIT"), align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.UnitName))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".UnitId",
                                        rules:
                                          _vm.formData.fromaDataRules.UnitId
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                "value-key": "id",
                                                filterable: "",
                                                placeholder: " ",
                                                clearable: "true"
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.unitchange(
                                                    scope.row
                                                  )
                                                }
                                              },
                                              model: {
                                                value: scope.row.UnitId,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    scope.row,
                                                    "UnitId",
                                                    $$v
                                                  )
                                                },
                                                expression: "scope.row.UnitId"
                                              }
                                            },
                                            _vm._l(
                                              _vm.formData.unitData,
                                              function(item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value
                                                  }
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: { label: _vm.$t("REMARK"), align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  !scope.row.isEgdit
                                    ? _c("span", [
                                        _vm._v(_vm._s(scope.row.Remark))
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop:
                                          "tableData." +
                                          scope.$index +
                                          ".Remark"
                                      }
                                    },
                                    [
                                      scope.row.isEgdit
                                        ? _c("el-input", {
                                            attrs: { clearable: "true" },
                                            model: {
                                              value: scope.row.Remark,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "Remark",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.Remark"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: _vm.$t("OPERATION"),
                            align: "center"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-button", {
                                    attrs: {
                                      title: _vm.$t("Delete"),
                                      type: "danger",
                                      size: "small",
                                      icon: "el-icon-delete",
                                      circle: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteItem(
                                          scope.$index,
                                          scope.row
                                        )
                                      }
                                    }
                                  })
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "right",
                          padding: "50px 0"
                        }
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.submitForm }
                          },
                          [_vm._v(_vm._s(_vm.$t("Save")))]
                        ),
                        _c("el-button", { on: { click: _vm.resetForm } }, [
                          _vm._v(_vm._s(_vm.$t("CANCEL")))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }